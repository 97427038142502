.topbar{
    width: 100%;
    height: 50px;
    background-color: #1d3557;
    position: sticky;
    top: 0;
    z-index: 999;
}

.topbarWrapper{
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-weight: bold;
    color: #F1FAEE;
    font-size: 25px;
}

.topbariconcontainer{
    position: relative;
    color: #F1FAEE;
    margin-left: 10px;
    cursor: pointer;
}

span.topbariconbadge {
    width: 15px;
    height: 15px;
    background: red;
    border-radius: 50%;
    font-size: 11px;
    position: absolute;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    top: -5;
    right: -3px;
    top: -3px;
}

.topright {
    display: flex;
    align-items: center;
}

img.topright-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 10px;
}