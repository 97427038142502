.widgetSm{
    flex: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    margin-right: 20px;
}

.widgetSmTitle{
    font-size: 22px;
    font-weight: 600;
}

li.widgesmlistitem {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
}

span.widgetsmusername {
    font-weight: 600;
}

span.widgetsmusertitle {
    color: gray;
}

.widgetsmuser {
    display: flex;
    flex-direction: column;
}

.widgetSmImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.widgetsmbutton{
    display: flex;
    align-items: center;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    background-color: #eeeef7;
    color: #555;
    cursor: pointer;
}

.widgetsmicon{
    font-size: 16px !important;
    margin-right: 10px;
}

