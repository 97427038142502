.product{
    flex: 4;
    padding: 20px;
}

.productTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.productAddButton{
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.productTopLeft{
    flex: 2;
}
.productTopRight{
    flex: 1;
}

.productTopRight{
    padding: 20px;
    margin: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.productInfoImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.productTop{
    display: flex;
}

.productInoTop{
    display: flex;
    align-items: center;
}

.productName{
    font-weight: 600;
}

.productIfoBottem{
    margin-top: 10px;
}

.productInfoItem{
    display: flex;
    justify-content: space-between;
    width: 150px;
    margin-top: 10px;
    border-bottom: 1px solid gray;
    padding-bottom: 5px;
}

.productInfoValue{
    font-weight: 300;
}

.productBottem{
    padding: 20px;
    margin: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.productForm{
    display: flex ;
    justify-content: space-between;
}

.productFormLeft {
    display: flex;
    flex-direction: column;
}

.productFormLeft > label{
    margin-bottom: 10px;
    color: gray;
}

.productFormLeft > input{
    margin-bottom: 10px;
    border: none;
    padding: 5px;
    border-bottom: 1px solid gray;
}

.productFormLeft > select{
    margin-bottom: 10px;
}

.productUploadImg{
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 10px;
}

.productFormRight {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.productUpload {
    display: flex;
    align-items: center;
}

.productButton{
    border: none;
    padding: 5px;
    border-radius: 5px;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    cursor: pointer;
}