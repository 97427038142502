.sidebar {
    flex: 1;
    background-color: #F1FAEE;
    height: calc(100vh - 50px);
    position: sticky;
    top: 50px;
}

.sidebarWrapper {
    color: #457B9D;
    padding: 10px 20px;
}

.sidebarMenu {
    margin-bottom: 10px;
}

h3.sidebarTitle {
    font-size: 13px;
}

.sidebarList {
    list-style: none;
    padding: 5px;
}

.sidebarListItem {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
    background-color: #A8DADC;
}

.sidebaricon {
    margin-right: 5px;
    font-size: 20px;
}

hr.sidebar-hr {
    border: 1px outset;
    width: 96%;
    margin-bottom: 14px;
}

.sidelink{
    text-decoration: none;
    color: inherit;
}