.featured {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.featuredItem {
    flex: 1;
    margin: 10px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.featuredTitle{
    font-size: 20px;
}

.featuredMoneyContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

span.featuredMoney {
    font-size: 30px;
    font-weight: 600;
}

span.featuredMoneyRate {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 17px !important;
    margin-left: 5px;
    color: green;
}

.featuredIcon.negative{
    color: red;
}

span.featuredSub {
    font-size: 15px;
    color: gray;
}