.widgetLg{
    flex: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    flex: 2;
}

.widgetLgTitle{
    font-size: 22px;
    font-weight: 600;
}

.widgetLgImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.widgetLgButton{
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
}

.widgetLgButton.Approved{
    background-color: #e5faf2;
    color: #3bb077;
}

.widgetLgButton.Declined{
    background-color: #fff0f1;
    color: #d95087;
}

.widgetLgButton.Pending{
    background-color: #ebf1fe;
    color: #3bb077;
}

table.widgetLgTable {
    width: 100%;
    border-spacing: 20px;
}

th.widgetLgTh {
    text-align: left;
}

.widgetLgUser {
    display: flex;
    align-items: center;
    font-weight: 600;
}
