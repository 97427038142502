.newuser{
    flex: 4;
    display: flex;
    flex-direction:column;
    justify-content: space-evenly;
    align-items: center;
}

.newUserForm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.newUserItem{
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
}

.newUserItem>label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: gray;
}

.newUserItem>input {
 height: 20px;
 padding: 10px;
 border: 1px solid gray;
 border-radius: 5px;
}

.newUserGender>input{
    margin-top: 15px;
}

.newUserGender>label{
    margin: 10px;
    font-size: 18px;
    color: #555;
}

.newUserSelect{
    height: 40px;
    border-radius: 5px;
}

.newUserButton{
    width: 200px;
    border: none;
    background-color: darkblue;
    color: white;
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
}