.newproduct {
    flex: 4;
    padding: 20px;
}
.addproductform{
    margin-top: 10px;
}

.addproductitem{
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.addproductitem > label{
color: gray;
font-weight: 600;
margin-bottom: 10px;
}

.addproductitem > input{
     padding: 10px;
}

.addproductitem > select{
    padding: 10px;
}

.addcreatebutton{
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    cursor: pointer;
}